import React from 'react'

import * as styles from './PageBannerPolicy.module.css'

const PageBannerPolicy = ({ title, description, theme }) => {
  const setTheme = theme ? (theme.toLowerCase()).replace(' ', '') : null;
  return (
    <div className={`${styles.bannerWrapper}${setTheme ? ` ${styles[setTheme]}` : ''}`}>
        <div className={styles.bannerContainer} >
          <h1 className='h3'>{title}</h1>
          <h4 className='h3'>{description}</h4>
        </div>
    </div>
  )
}

export default PageBannerPolicy
