import Breadcrumbs from '../components/atoms/Breadcrumbs/Breadcrumbs'
import React from 'react'
import Container from '../components/atoms/Container/Container';
import Layout from '../components/organisms/Layout/Layout';
import Seo from '../components/organisms/Seo/Seo';
import PageBannerPolicy from '../components/atoms/PageBannerPolicy/PageBannerPolicy';
import * as styles from './privacy.module.css';
import PageTitle from "../components/atoms/PageTitle/PageTitle"
import { graphql } from 'gatsby';

const PrivacyPage = ({data}) => {
    const page = (data && data.policyPage) || false;

    return ( 
        <Layout>
          <Seo title={page && page.pageName} description={page.metaDescription} image={page.metaPreviewImage ? `https:${page.metaPreviewImage.file.url}` : ''}/>
          <PageBannerPolicy
                title={page && page.metaTitle}
                description={page && page.metaDescription}
            />
            <Container size="medium">
                    <Breadcrumbs crumbs={[{link: '/', label:'Home'}, {label:`${page && page.pageName}`}]}/>
                    <PageTitle customMode={true}>{page && page.pageName}</PageTitle>
                    <div className={styles.privacyBody}>
                        {page && page.blocks.map((block,index) => (
                            block.blocks.map((content, indexC) => (
                                <div key={indexC} dangerouslySetInnerHTML={{
                                    __html: content.content.childMarkdownRemark.html
                                  }}>
                                </div>
                            ))
                        ))}
                        {/* <div className={styles.privacyTitle}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur blandit urna id eleifend vehicula. Sed nec enim felis. Ut commodo, odio sit amet ultricies sollicitudin, nibh sem eleifend velit, quis lacinia nisi velit a mauris semper dui a pharetra imperdiet.
                        </div>

                        <p>
                            Quisque eget velit diam. Donec id tristique ex, in hendrerit est. Sed placerat et metus eu suscipit. Aliquam sed tortor sed elit tempor molestie. Aenean in augue nibh. Sed eget rutrum diam, vel volutpat dolor. Fusce semper dui a pharetra imperdiet. Phasellus nisl justo, congue ut ligula ac, malesuada lacinia erat. 
                        </p>
                        <h6>Lorem ipsum dolor sit amet</h6>
                        <ul>
                            <li>Aenean placerat ex lobortis libero auctor</li>
                            <li>Vel semper turpis sagittis. </li>
                            <li>In mattis sit amet est vel dapibus. </li>
                            <li>Aliquam eget tellus hendrerit, condimentum sem</li>
                        </ul>
                
                        <p>Duis eget tincidunt nulla. Morbi aliquam velit vitae eleifend volutpat. Cras ultrices leo nec suscipit egestas. Proin neque erat, scelerisque ac mollis a, efficitur egestas magna. Vivamus ultricies, massa a efficitur maximus, nibh metus posuere turpis, sit amet fringilla tellus lorem at est. Pellentesque consectetur, ex aliquet euismod auctor, nulla est bibendum est, id dictum est arcu nec libero. Quisque vel rutrum lorem, eu efficitur dolor. Pellentesque in ante nisl. Vivamus quis elit urna. Phasellus consequat fermentum augue sed pharetra. Aliquam nec ante et orci condimentum congue. In eu justo dapibus, consequat nisi id, tristique tortor. Mauris consequat posuere nulla in vulputate. Quisque ac varius erat.</p>
                        <p>Integer eget vestibulum urna. Quisque at aliquet augue, id tincidunt turpis. Nullam vestibulum erat vel ex vehicula, sed elementum dui volutpat. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce lacinia et nulla nec pretium. Suspendisse finibus imperdiet magna at tempus. Morbi et turpis sit amet eros interdum pretium non a erat. Donec vehicula lacus sit amet augue sagittis faucibus. In aliquet semper purus nec fermentum. Nullam accumsan condimentum nisi ac porttitor. Etiam dignissim odio a nulla facilisis sollicitudin. In hac habitasse platea dictumst. Morbi eget suscipit ex, molestie bibendum est. Donec a dui ut leo facilisis porta sit amet non est.</p>
                        <p>Integer eget vestibulum urna. Quisque at aliquet augue, id tincidunt turpis. Nullam vestibulum erat vel ex vehicula, sed elementum dui volutpat. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce lacinia et nulla nec pretium. Suspendisse finibus imperdiet magna at tempus. Morbi et turpis sit amet eros interdum pretium non a erat. Donec vehicula lacus sit amet augue sagittis faucibus. In aliquet semper purus nec fermentum. Nullam accumsan condimentum nisi ac porttitor. Etiam dignissim odio a nulla facilisis sollicitudin. In hac habitasse platea dictumst. Morbi eget suscipit ex, molestie bibendum est. Donec a dui ut leo facilisis porta sit amet non est.</p>
                        <p>Integer eget vestibulum urna. Quisque at aliquet augue, id tincidunt turpis. Nullam vestibulum erat vel ex vehicula, sed elementum dui volutpat. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce lacinia et nulla nec pretium. Suspendisse finibus imperdiet magna at tempus. Morbi et turpis sit amet eros interdum pretium non a erat. Donec vehicula lacus sit amet augue sagittis faucibus. In aliquet semper purus nec fermentum. </p> */}
                    </div>
            </Container>
        </Layout>
    );
}
 
export default PrivacyPage;

export const query = graphql`
    query ContentfulPagePrivacyPolicy {
        policyPage: contentfulPage(pageName: {eq: "Privacy Policy"}) {
            pageName
            pageTitle
            metaCanonicalLink
            metaDescription
            metaTitle
            metaPreviewImage{
              file {
                url
              }
            }
            blocks {
                blocks {
                    ... on ContentfulBlockContent {
                        name
                        content {
                            childMarkdownRemark {
                            html
                            }
                        }
                    }
                }
            }
        }
    }
`